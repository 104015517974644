const sendSlack = (title, body) => {
	fetch(
		'https://hooks.slack.com/services/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',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify({
				"blocks": [
					{
						"type": "section",
						"text": {
							"type": "mrkdwn",
							"text": title
						}
					},
					{
						"type": "section",
						"text":
						{
							"type": "mrkdwn",
							"text": body
						}
					}
				]
			}),
		}
	)
		.then((_) => { })
		.catch((_) => { })
}

export default (_ctx, inject) => {
	inject('sendSlack', sendSlack)
}