export default async function (context) {
	// If the user is not authenticated
	if (context.$cookie.get('token')) {
		let currentPageRoute = 'dashboard';
		const menubarTab = context.store.state.settings.menubarTab
		const userPermissions = context.store.state.settings.userPermissions
		const route = context.route.path;
		const mainRoute = route.split('/')[1]

		if (mainRoute) {
			menubarTab.map((menu) => {
				if (menu.children && menu.children.length > 0) {
					menu.children.map((x) => {
						if (x.to.includes(mainRoute)) {
							const subMainRoute = route.split('/').length >= 3 ? route.split('/')[2] : null
							if (x.code) {
								if (subMainRoute && x.to.includes(subMainRoute)) {
									currentPageRoute = x.code
								} else if (!subMainRoute) {
									currentPageRoute = x.code
								}
							} else {
								currentPageRoute = menu.code
							}
						}
						return x;
					})
				} else if (menu.to && menu.to.includes(mainRoute)) {
					currentPageRoute = menu.code
				}

				return menu
			})
		}

		const userData = context.$cookie.get('user')

		if (userData && userData.user_type !== 1) {
			await context.store.dispatch('settings/permissionCode', currentPageRoute) // The save the current route permission code into cookies
			if (userPermissions.length === 0) {
				await context.store.dispatch('settings/userPermissions') // To fetch the user permissions data
			}
		}
	}
}