/**
 * Usage this custom-toaster.js
 * Import the CustomToaster component into the default layout component
 * and using all the config files are in dialog.js
 * @param {
 * 		display: Boolean,
 * 		type: String, // success, confirm, error. Default is success
 * 		title: String, // The text to display on the dialog
 * 		func: Function, // The callback function after clicking Confirm button (only type === confirm)
 * 		color: String, // Color for title and confirm button
 * 		confirmText: String, // The text to replaced to confirm button, default text is save
 *		icon: String, // mdi-icon
 * 		description: String
 * }
 */
export default (_ctx, inject) => {
	const { store } = _ctx

	function callToaster(params = {}) {
		const { type, title, subtitle, func, color, confirmText, icon, description } = params
		_ctx.store.dispatch('dialog/setShowDialog', {
			display: true,
			type: type || 'success', // ['success', 'confirm', 'error], default success
			title,
			subtitle,
			func,
			color,
			confirmText,
			icon,
			description
		})

		// Create promise for Confirm and Cancel Dialog only
		if (type === 'confirm') {
			return new Promise((resolve) => {
				const unwatch = store.watch(
					(state) => state.dialog.confirmButtonStatus,
					(newVal) => {
						resolve(newVal);
						// Clear memory leak issue
						unwatch();
					}
				);
			});
		} else {
			return Promise.resolve();
		}
	}

	inject('toaster', $event => callToaster($event))
}