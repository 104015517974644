
import { mapState } from 'vuex'
export default {
    name: 'Affiliate',
    data() {
        return {
            title: 'Control Panel',
            drawer: true,
            rightDrawer: false,
            notifications: [],
            items: [
                {
                    icon: 'mdi-fire',
                    title: 'dashboard',
                    to: '/agent/affiliate',
                },
                {
                    icon: 'mdi-face-agent',
                    title: 'player',
                    to: '/agent/affiliate/player',
                },
                {
                    icon: 'mdi-bank',
                    title: 'banks',
                    to: '/agent/affiliate/banks',
                },
            ],
        }
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
        isDarkMode: {
            get() {
                return this.$cookie.get('darkMode') || this.$vuetify.theme.dark
            },
            set() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
                this.$cookie.set('darkMode', this.$vuetify.theme.dark)
            },
        },
        availableLocales() {
            return this.languages.filter(
                (language) => language.code !== this.systemLocale
            )
        },
        ...mapState({
            languages: (state) => state.affiliate.frontend.languages,
        }),
    },
    created() {
        this.$vuetify.theme.dark = this.$cookie.get('darkMode') || false
    },
    async mounted() {
        this.switchLanguage(null)
        await this.$store.dispatch('affiliate/frontend/languages')
        await this.$store.dispatch('language-strings/affiliateLanguageStrings')
    },
    methods: {
        connectAgain() {
            this.socket.emit('message', 'clicked-again')
        },
        playNotification(toastText) {
            this.$toast.info(toastText)
            this.$refs.audioUI.play()
        },
        soundToggle() {
            if (this.$cookie.get('sound')) {
                this.$cookie.set('sound', false)
            } else {
                this.$cookie.set('sound', true)
                this.playNotification(this.tl('soundEnabled'))
            }
        },
        getCurrentLocale(localeCode) {
            if (!localeCode) return null
            const currentLocaleData = this.languages.find(
                (el) => el.code === localeCode
            )
            if (!currentLocaleData) return null
            return currentLocaleData.flag_icon
        },
        switchLanguage(code) {
            if (code) {
                this.$cookie.set('locale', code)
                window.location.reload()
            } else {
                this.$cookie.set(
                    'locale',
                    this.$cookie.get('locale') ?? 'en-US'
                )
                if (!this.$cookie.get('locale')) {
                    window.location.reload()
                }
            }
        },
        onShowRightDrawer() {
            this.rightDrawer = !this.rightDrawer
        },
        agentLogout() {
            if (confirm(this.tl('areYouSure'))) {
                this.$store.dispatch('affiliate/auth/logout')
            }
        },
        onSwitchLocale(locale) {
            this.$cookie.set('locale', locale.code)
            this.$store.dispatch('settings/locale', locale.code)
        },
    },
}
