import Vue from "vue"
import { mapState } from 'vuex'
import VueTheMask from 'vue-the-mask'
import { VMoney } from 'v-money'
import { load } from '@fingerprintjs/fingerprintjs'
// import writeXlsxFile from 'write-excel-file'

Vue.use(VueTheMask)
// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__global__) {
	Vue.__global__ = true
	Vue.mixin({
		directives: { money: VMoney },
		data() {
			return {
				newCode: [],
				globalMoney: {
					decimal: ".",
					thousands: ",",
					prefix: "",
					suffix: "",
					precision: "",
					masked: true /* doesn't work with directive */,
				},
				allowPhaseTwoToClient: false
			}
		},
		computed: {
			...mapState({
				languages: (state) => state.master.languages,
				userPermissions: (state) => state.settings.userPermissions,
				currentPermissionCode: (state) => state.settings.currentPermissionCode,
				systemLocale: (state) => state.settings.locale,
				// systemLocales: (state) => state.frontend.languages,
				systemLocales: (state) => state.master.languages,
				cmsLanguages: (state) => state.cmses.selectedCurrencyLanguages,
				hasDefaultComponentValue: (state) => state.cmses.hasDefault,
				allLanguages: (state) => state.frontend.allLanguages,
				// translationString: (state) => state['language-strings'].translationString,
				translationString: (state) => state.master.translationString,
				globalDefaultCurrency: (state) => state.settings.globalSelectedCurrency,
				permissions: (state) => state.settings.userPermissions,
				landingCurrency: (state) => state.settings.currency
			}),
			currentPermissionCode() {
				return this.$store.state.settings.currentPermissionCode
			},
			isCryptoCurrency() {
				if (this.$cookie.get('currency_type')) {
					const currencyType = parseFloat(this.$cookie.get('currency_type'))
					// 1 is fiat currency, 2 is crypto currency
					return currencyType === 2
				}
				return false;
			}
		},
		mounted() {
			// Add try catch, since first reload this.$cookie is undefined
			try {
				if (this.$cookie?.get('locale')) {
					this.$store.dispatch('settings/locale', this.$cookie?.get('locale'))
				}
				if (!this.$cookie?.get('permissionCode')) {
					this.$cookie.set('permissionCode', 'dashboard')
				}
			} catch (error) { }
		},
		methods: {
			// Logout user
			logout() {
				if (confirm(this.tl('areYouSure'))) {
					this.$store.dispatch('auth/logout')
				}
			},
			// Check URL
			isURL(string) {
				let url;
				try {
					url = new URL(string);
				} catch (_) {
					if (string && !string.includes('https://') && !string.includes('http://')) {
						if (string.substring(0, 4) === 'www.') {
							return true
						}
					}
					return false;
				}
				return url.protocol === "https:" || url.protocol === "http:";
			},
			copyToClipboard(text) {
				navigator.clipboard.writeText(text)
				// notify user
				this.$toast.show(this.tl('contentCopied'))
			},
			tl(code) {
				if (Object.keys(this.translationString).length === 0) {
					return code
				}

				if (!this.translationString[code]) {
					const text = {}
					const form = {}
					for (let index = 0; index < this.systemLocales.length; index++) {
						text[this.systemLocales[index].code] = code;
					}
					form.text = { ...text };
					form.code = code;
					form.status = true;
					form.used_in = [2]

					//  ? Uncommented this line if you wanna to add dictionary automatically, [dev mode only]

					// if (!this.$store.state['language-strings'].newCodes.includes(code) && this.$cookie.get('token')) {
					// 	this.$store.dispatch(
					// 		'language-strings/addLanguageStrings',
					// 		form
					// 	)
					// }
					// this.$store.dispatch('language-strings/newCode', code);
					return code
				}

				return this.translationString[code]
			},
			parseFloatNumber(val) {
				try {
					return Number.parseFloat(val)
				} catch (error) {
					return false
				}
			},
			formatCurrencyJS(value, removeDecimal = false) {
				if (!value) value = 0;
				const response = this.$currencyJs(
					parseFloat(value),
					{ separator: ',', symbol: '' }
				).format();
				if (removeDecimal) {
					return response.replace('.00', '')
				}
				return response
			},
			readableNumber(number) {
				if (number) {
					return new Intl.NumberFormat().format(parseFloat(number))
				}
				return 0
			},
			// Generate the excel.
			generateExcel(fileName, excelData) {
				let columnNames = ''
				const csvString = excelData.map((item) => {
					const tempColumnNames = []
					const columnValues = []
					for (const [key, value] of Object.entries(item)) {
						columnValues.push(value)
						tempColumnNames.push(key)
					}
					columnNames = tempColumnNames
					return columnValues
				})

				columnNames = columnNames.join()
				csvString.forEach(function (row) {
					columnNames += '\n' + row.join(',')
				})

				const hiddenElement = document.createElement('a')
				hiddenElement.href =
					'data:text/csv;charset=utf-8,' +
					encodeURIComponent('\uFEFF' + columnNames)
				hiddenElement.target = '_blank'
				hiddenElement.download = fileName + '.csv'
				hiddenElement.click()
			},
			async generateExcelXlsx(fileName, excelDataObject) {
				// await writeXlsxFile(excelDataObject, {
				// 	// columns, // (optional) column widths, etc.
				// 	fileName: fileName + '.xlsx'
				// })
				await this.$store.dispatch('download-excel-file/downloadExcelFile', {
					data: excelDataObject,
					fileName
				})
			},
			// ? DD-MM-YYYY HH:MM:SS format
			reverseDate(date = "2020-01-01") {
				if (date?.split(" ").length > 1) {
					const filterDate = date.split(' ')[0]
					if (filterDate.split("-")[0].length === 4) {
						return filterDate.split("-").reverse().join("-") + ' ' + date.split(' ')[1];
					}
					return date;
				} else if (date?.split("-")[0].length === 4) {
					return date.split("-").reverse().join("-");
				} else {
					return date;
				}
			},
			momentDate(date, showTime = true) {
				if (!date) return "-";
				return this.$moment(date)
					.local()
					.format(`DD-MM-YYYY ${showTime ? 'HH:mm:ss' : ''} `)
			},
			getPromotionNameForLocales(promotions) {
				// ? If No promotion name available
				if (!promotions) return this.tl('noDataAvailable')

				// ? If the promotion name exists, but there is only name Language Name
				if (Object.keys(promotions).length === 1)
					return promotions[Object.keys(promotions)[0]]

				// ? If the promotion name have multiple languages
				return promotions[this.systemLocale] || promotions['en-US'] || '-'
			},
			checkUserPermission(type, code = null) { // type: create, delete, update, view
				const permissionsByCurrentPermissionCode = this.userPermissions.find(el => el.code === (code || this.currentPermissionCode));
				if (!permissionsByCurrentPermissionCode) {
					return this.$cookie.get('user')?.user_type === 1
				}

				if (type === 'view') {
					return permissionsByCurrentPermissionCode.can_view
				}
				if (type === 'create') {
					return permissionsByCurrentPermissionCode.can_create
				}
				if (type === 'delete') {
					return permissionsByCurrentPermissionCode.can_delete
				}
				if (type === 'update') {
					return permissionsByCurrentPermissionCode.can_update
				}
			},
			async visitorId() {
				const fingerPrint = await load()
				const visitorId = await (await fingerPrint.get()).visitorId
				return visitorId
			},
			typingSpecialNumbers(event) {
				const notAllowDash = /^[0-9.,-]/g;
				if (!notAllowDash.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
					event.preventDefault();
				}

			},
			rulesEmptyRequired(text = this.tl('isRequired')) {
				return [v => !!v || text]
			},
			validateForm() {
				// using to validate the form, the ref name should be 'form'
				if (!this.$refs.form.validate()) {
					this.$toast.error(this.tl('pleaseEnterCompleteAllTheForms'))
				}
				return this.$refs.form.validate()
			},
			// Only CMS Component Section
			appliedDefault(language) {
				return !this.hasDefaultComponentValue ||
					(this.hasDefaultComponentValue && language.code === 'en-US')
			},
			cmsImagePath(src, type) {
				if (!src) return null
				const snakeCase = type.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
				const component = snakeCase.substring(1, snakeCase.length)
				return `${this.$config.BUCKET}/assets/component_contents/${component}/${src}`
			},
			changeObjToArrLangCmsOnly(currency) {
				if (typeof currency !== 'string') return []

				const getLandingLanguageByCurrency = this.landingCurrency.find(el => {
					if (el?.code?.toLowerCase() === (currency)?.toLowerCase()) {
						return true;
					}
					return false
				})

				if (getLandingLanguageByCurrency) {
					const getLandingSupportedLanguages = getLandingLanguageByCurrency?.supported_languages
					// Format Response
					// [{is_active: Boolean, language: 'th-TH'}]
					if (getLandingSupportedLanguages) {
						const arr = []
						getLandingSupportedLanguages.forEach(langData => {
							const form = {}
							// this below Array Find is for getting the language flag and language name from Languages stores
							const findLanguage = this.allLanguages.find(
								(el) => {
									if (el.code === langData.language && langData.is_active === true) return true;
									else return false;
								}
							)
							if (findLanguage) {
								form.name = findLanguage.name
								form.flag_icon = findLanguage.flag_icon
								form.code = findLanguage.code
								arr.push(form)
							}
						})
						return arr.sort((a, b) => {
							if (a.name === 'English') {
								return 1; // 'English' should be at the end
							} else if (b.name === 'English') {
								return -1; // 'English' should be at the end
							} else {
								// For other languages, use default alphabetical order
								return a.name.localeCompare(b.name);
							}
						});
					}
				}
				return []
			},
			getFirstTwoElement(arr) {
				const array = JSON.parse(JSON.stringify(arr))
				const newArr = array.splice(0, 2)
				return newArr
			},
			isSuperAdmin() {
				return ~~this.$cookie.get('user')?.user_type === 1
			},
			sumField(data, key) {
				try {
					if (!data) return null
					if (data?.data) {
						return data?.data?.reduce(
							(a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
							0
						)
					} else {
						return data?.reduce(
							(a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
							0
						)
					}
				} catch (error) {

				}
			},
			sumCount(data, key) {
				try {
					if (!data) return null;
					return data?.reduce(
						(a, b) => parseInt(a) + (parseInt(b[key]) || 0),
						0
					)
				} catch (error) {

				}

			},
			disabledPlayerProfilePage() {
				if (this.isSuperAdmin()) return false
				const playerPermission = this.permissions.find(el => el.code === 'player_list')
				if (!playerPermission) return false
				if (!playerPermission.can_view) return true
				return false
			},
			goToPlayerProfile(playerId) {
				if (this.disabledPlayerProfilePage()) return null
				window.open(`/players/players/${playerId}`)
			},
			sum() {
				let total = 0
				for (let i = 0; i < arguments.length; i++) {
					total += arguments[i]
				}
				return total
			},
			playerAge(createdAt) {
				return `${this.$moment(this.$moment().local().format('YYYY-MM-DD'))
					.local()
					.diff(
						this.$moment(createdAt)
							.local()
							.format('YYYY-MM-DD'),
						'days'
					)} ${this.tl('days')}`
			}
		}
	}) // Set up your mixin then
}
