
export default {
    name: 'Blank',
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: '/csl.png',
                },
            ],
        }
    },
    computed: {
        wlType() {
            return this.$store.state.auth.wlType
        },
        bgImage() {
            const internationalBg = '/assets/background.jpg'
            const laoBg = '/assets/la-bg.png'
            if (this.wlType) {
                if (~~this.wlType === 1) return internationalBg
                else return laoBg
            }

            const wlt = this.$cookie.get('wlt')
            if (wlt) {
                const type = wlt
                if (~~type === 1) {
                    return internationalBg
                }
                return laoBg
            }
            return internationalBg
        },
    },
}
