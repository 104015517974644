// These routes are to define routes between main website and sub websites
/**
 * Usage
 * this.$axios.get('players.playerList')
 */
export const routes = {
	auth: {
		login: {
			user: '/users/login',
			agent: '/agents/login'
		},
		logout: {
			user: '/users/session/logout',
			agent: '/agents/session/logout'
		},
		verify: {
			user: '/users/verify-tfa-otp',
			agent: '/agents/verify-tfa-otp'
		}
	},
	players: {
		playerList: {
			user: '/player-management/players',
			agent: '/agents/player-management/players'
		},
	},
	master: {
		languages: {
			user: '/master/languages',
			agent: '/agents/master/languages'
		},
		languageStrings: {
			user: '/master-language-string',
			agent: '/agents/master/language-string'
		}
	},
	users: {
		info: {
			user: '/users/me/info',
			agent: '/agents/me/info'
		},
		user: {
			user: '/users',
			agent: '/agents'
		},
		verifyFinanceOtp: {
			user: '/users/verify-finance-tfa-otp',
			agent: '/agents/verify-finance-tfa-otp',
		},
		setupFinanceTfa: {
			user: '/users/setup-finance-tfa',
			agent: '/agents/setup-finance-tfa'
		},
		permissions: {
			user: '/users/permissions',
			agent: '/agents/permissions'
		}
	},
	public: {
		type: {
			user: '/white-label/type',
			agent: '/white-label/type'
		},
	}
};
